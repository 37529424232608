<button mat-mini-fab
    color="primary"
    style="transform: none;  margin:0 4px;"
    matTooltip="Open"
    matTooltipPosition="below"
    matTooltipClass="app-tooltip"
    [disabled]="storageService.isEmpty()"
    (click)="openDialog()"
>
    <mat-icon>folder_open</mat-icon>
</button>