<h1 mat-dialog-title>Import image</h1>
<div mat-dialog-content class="upload-image-dialog-content"
  (drop)="onDrop($event)" (dragover)="onDragOver($event)"
>
  <div class="upload-button-row">
    <button type="button" mat-raised-button (click)="fileInput.click()" (drop)="onDrop($event)">Choose File</button>
    <img *ngIf="data" class="preview" [src]="displayableData" />
    <span class="filename">{{name}}</span>
  </div>
  <mat-form-field>
    <mat-label>x</mat-label>
    <input matInput [(ngModel)]="x">
  </mat-form-field>
  <mat-form-field>
    <mat-label>y</mat-label>
    <input matInput [(ngModel)]="y">
  </mat-form-field>
  <mat-form-field>
    <mat-label>width</mat-label>
    <input matInput [(ngModel)]="width">
  </mat-form-field>
  <mat-form-field>
    <mat-label>height</mat-label>
    <input matInput [(ngModel)]="height">
  </mat-form-field>
  <div>
      <mat-checkbox class="example-margin" [(ngModel)]="preserveAspectRatio">Preserve Aspect Ratio</mat-checkbox>
  </div>
  <input hidden (change)="onFileSelected(fileInput)" accept="image/*" #fileInput type="file">
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="onCancel()" color="primary">Cancel</button>
  <button mat-raised-button (click)="onUploadImage()" color="accent" [disabled]="!data">Import</button>
</div>