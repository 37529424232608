<h1 mat-dialog-title>Export as SVG</h1>
<div mat-dialog-content>
  <div class="export-content">
    <div class="export-form">
      <div class="style-form-title">
        Style
      </div>
      <div class="style-form-fill">
        <div>
          <mat-checkbox class="example-margin" [ngModel]="cfg.fill" (ngModelChange)="cfg.fill=$event;cfg.stroke=cfg.stroke||!$event">Fill</mat-checkbox>
        </div>
        <mat-form-field>
          <mat-label>Fill Color</mat-label>
          <input matInput [(ngModel)]="cfg.fillColor" [disabled]="!cfg.fill">
        </mat-form-field>
      </div>
      <div class="style-form-stroke">
        <div>
          <mat-checkbox class="example-margin" [ngModel]="cfg.stroke" (ngModelChange)="cfg.stroke=$event;cfg.fill=cfg.fill||!$event">Stroke</mat-checkbox>
        </div>
        <div class="style-form-stroke-fields">
          <div class="style-form-stroke-field">
            <mat-form-field>
              <mat-label>Stroke Color</mat-label>
              <input matInput [(ngModel)]="cfg.strokeColor" [disabled]="!cfg.stroke">
            </mat-form-field>
          </div>
          <div class="style-form-stroke-field">
            <mat-form-field>
              <mat-label>Stroke width</mat-label>
              <input matInput [(ngModel)]="cfg.strokeWidth" [disabled]="!cfg.stroke">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="viewbox-form-title">
        <div>ViewBox</div>
        <button mat-flat-button (click)="refreshViewbox()" color="basic" class="reset-button">Reset</button>
      </div>
      <div class="viewbox-form-field">
        <mat-form-field>
          <mat-label>X</mat-label>
          <input matInput appFormatter [(numberValue)]="x">
        </mat-form-field>
      </div>
      <div class="viewbox-form-field">
        <mat-form-field>
          <mat-label>Y</mat-label>
          <input matInput appFormatter [(numberValue)]="y">
        </mat-form-field>
      </div>
      <div class="viewbox-form-field">
        <mat-form-field>
          <mat-label>Width</mat-label>
          <input matInput appFormatter formatterType="positive-float" [(numberValue)]="width">
        </mat-form-field>
      </div>
      <div class="viewbox-form-field">
        <mat-form-field>
          <mat-label>Height</mat-label>
          <input matInput appFormatter formatterType="positive-float" [(numberValue)]="height">
        </mat-form-field>
      </div>
    </div>
    <div class="preview">

      <app-path-preview
        [x]="x"
        [y]="y"
        [width]="width"
        [height]="height"
        [path]="data.path"
        [fillColor]="cfg.fill?cfg.fillColor:'none'"
        [strokeColor]="cfg.stroke?cfg.strokeColor:'none'"
        [strokeWidth]="cfg.strokeWidth"
      ></app-path-preview>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="onCancel()" color="primary">Cancel</button>
  <button mat-raised-button (click)="onExport()" color="accent" [disabled]="false">
    Download
  </button>
</div>